import React, { useState } from 'react';
import Puzzle from './Puzzle';
import './styles/App.css';

function App() {
  const [image, setImage] = useState(null);
  const [rows, setRows] = useState(5);
  const [columns, setColumns] = useState(5);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRowsChange = (e) => {
    setRows(Number(e.target.value));
  };

  const handleColumnsChange = (e) => {
    setColumns(Number(e.target.value));
  };

  return (
    <div className="App">
      <h1>A Quick Jigsawscapes for 100 days Memory with SiRong Lin</h1>
      <div className="controls">
        <input type="file" accept="image/*" onChange={handleImageUpload} />
        <label>
          Rows:
          <input type="number" value={rows} onChange={handleRowsChange} min="1" />
        </label>
        <label>
          Columns:
          <input type="number" value={columns} onChange={handleColumnsChange} min="1" />
        </label>
      </div>
      {image && <Puzzle image={image} rows={rows} columns={columns} />}
    </div>
  );
}

export default App;
