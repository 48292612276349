import React, { useState, useEffect } from 'react';
import './styles/Puzzle.css';

const Puzzle = ({ image, rows, columns }) => {
  const [grid, setGrid] = useState([]);
  const [pieces, setPieces] = useState([]);
  const [completed, setCompleted] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = image;
    img.onload = () => {
      const gridSize = 500; // Define the size of the grid (500x500)
      const pieceWidth = gridSize / columns;
      const pieceHeight = gridSize / rows;
      const newPieces = [];
      const newGrid = Array.from({ length: rows * columns }, () => null);

      // Create a canvas to resize the image
      const canvas = document.createElement('canvas');
      canvas.width = gridSize;
      canvas.height = gridSize;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0, gridSize, gridSize);

      for (let y = 0; y < rows; y++) {
        for (let x = 0; x < columns; x++) {
          const pieceCanvas = document.createElement('canvas');
          pieceCanvas.width = pieceWidth;
          pieceCanvas.height = pieceHeight;
          const pieceCtx = pieceCanvas.getContext('2d');
          pieceCtx.drawImage(
            canvas,
            x * pieceWidth,
            y * pieceHeight,
            pieceWidth,
            pieceHeight,
            0,
            0,
            pieceWidth,
            pieceHeight
          );
          newPieces.push({
            x,
            y,
            imgSrc: pieceCanvas.toDataURL(),
            order: y * columns + x,
          });
        }
      }

      setPieces(shuffleArray(newPieces));
      setGrid(newGrid);
    };
  }, [image, rows, columns]);

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  const handleDrop = (e, targetIndex) => {
    e.preventDefault();
    const draggedPieceIndex = e.dataTransfer.getData('pieceIndex');
    const fromGrid = e.dataTransfer.getData('fromGrid') === 'true';

    const draggedPiece = fromGrid ? grid[draggedPieceIndex] : pieces[draggedPieceIndex];

    const newGrid = [...grid];
    const newPieces = [...pieces];

    if (newGrid[targetIndex]) {
      if (fromGrid) {
        const temp = newGrid[targetIndex];
        newGrid[targetIndex] = draggedPiece;
        newGrid[draggedPieceIndex] = temp;
      } else {
        newPieces[draggedPieceIndex] = newGrid[targetIndex];
        newGrid[targetIndex] = draggedPiece;
      }
    } else {
      newGrid[targetIndex] = draggedPiece;
      if (fromGrid) {
        newGrid[draggedPieceIndex] = null;
      } else {
        newPieces[draggedPieceIndex] = null;
      }
    }

    setGrid(newGrid);
    setPieces(newPieces);
    checkCompletion(newGrid);
  };

  const handleDragStart = (e, pieceIndex, fromGrid) => {
    e.dataTransfer.setData('pieceIndex', pieceIndex);
    e.dataTransfer.setData('fromGrid', fromGrid);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleGridClick = (index) => {
    const newGrid = [...grid];
    const newPieces = [...pieces];
    const piece = newGrid[index];

    if (piece) {
      newGrid[index] = null;
      newPieces.push(piece); // Add the piece to the end of the candidate array
      setGrid(newGrid);
      setPieces(newPieces);
    }
  };

  const checkCompletion = (grid) => {
    for (let i = 0; i < grid.length; i++) {
      if (!grid[i] || grid[i].order !== i) {
        setCompleted(false);
        return;
      }
    }
    setCompleted(true);
  };

  return (
    <div className="main-container">
      {completed ? (
        <div>
          <h2>Congratulations, you completed the puzzle!</h2>
          <img src={image} alt="Completed puzzle" className="completed-image" />
        </div>
      ) : (
        <>
          <div className="puzzle-container">
            <div className="puzzle-grid" style={{ gridTemplateColumns: `repeat(${columns}, 1fr)`, gridTemplateRows: `repeat(${rows}, 1fr)` }}>
              {grid.map((piece, index) => (
                <div
                  key={index}
                  className="puzzle-grid-cell"
                  style={{ width: `${500 / columns}px`, height: `${500 / rows}px` }}
                  onDrop={(e) => handleDrop(e, index)}
                  onDragOver={handleDragOver}
                  onClick={() => handleGridClick(index)}
                >
                  {piece && (
                    <img
                      src={piece.imgSrc}
                      className="puzzle-piece"
                      alt="puzzle piece"
                      draggable
                      onDragStart={(e) => handleDragStart(e, index, true)}
                      style={{ width: '100%', height: '100%' }}
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
          <div className="puzzle-container">
            <div className="puzzle-pieces">
              {pieces.map(
                (piece, index) =>
                  piece && (
                    <img
                      key={index}
                      src={piece.imgSrc}
                      className="puzzle-piece"
                      alt="puzzle piece"
                      draggable
                      onDragStart={(e) => handleDragStart(e, index, false)}
                      style={{ width: `${500 / columns}px`, height: `${500 / rows}px`, margin: '5px' }} // Adjust the size and margin
                    />
                  )
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Puzzle;
